import {
  Dialog,
  DialogContent,
  DialogTitle,
} from "@tsc/component-library/lib/components";

import ScraperContent, {
  SCRAPER_MODAL_MODES,
} from "components/ScraperContent/ScraperContent";

const ScraperDialog = ({ open, onClose, saveScraper }) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Create Scraper</DialogTitle>
      <DialogContent dividers>
        <ScraperContent
          mode={SCRAPER_MODAL_MODES.CREATE}
          saveScraper={saveScraper}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ScraperDialog;
