import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Chip,
  ConfirmationDialog,
  IconButton,
  MaterialSymbol,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@tsc/component-library/lib/components";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { deleteDoc, doc } from "firebase/firestore";
import KeyMirror from "keymirror";
import { isEqual } from "lodash";

import dayjs from "configurations/dayjs";
import { db } from "configurations/firebase";
import { useScraperTypes } from "hooks/useScraperTypes";

export const SCRAPER_MODAL_MODES = KeyMirror({
  CREATE: null,
  UPDATE: null,
});

const emptyScraper = {
  name: "",
  is_active: false,
  last_scraped: null,
  query: "",
  metadata: {},
};

export const ScraperContent = ({
  defaultScraper,
  saveScraper,
  mode = SCRAPER_MODAL_MODES.UPDATE,
}) => {
  const navigate = useNavigate();
  const scraperTypes = useScraperTypes();
  const [open, setOpen] = useState(false);
  const [scraper, setScraper] = useState(defaultScraper ?? emptyScraper);

  useEffect(() => {
    setScraper(defaultScraper);
  }, [defaultScraper]);

  const handleChange = (value) => {
    try {
      setScraper(JSON.parse(value));
    } catch (error) {}
  };

  const handleFieldChange = (field, value) => {
    setScraper({
      ...scraper,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveScraper(scraper);
  };

  const handleDelete = async () => {
    if (scraper?.id) await deleteDoc(doc(db, "scrapers", scraper.id));
    setOpen(false);
    navigate("/scrapers");
  };

  const handleDeleteConfirmation = () => {
    setOpen(true);
  };

  let lastScraped = scraper?.last_scraped;
  try {
    if (!lastScraped) throw new Error();
    lastScraped = dayjs(scraper?.last_scraped).fromNow();
  } catch (error) {
    lastScraped = "Never";
  }
  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography variant="h6">Scraper Details</Typography>
        {mode === SCRAPER_MODAL_MODES.UPDATE && (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={handleDeleteConfirmation}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )}
      </Stack>
      <Stack gap={2} pl={2} flexGrow={1} width="100%">
        <TextField
          fullWidth
          label="Scraper Name"
          value={scraper?.name ?? ""}
          onChange={(e) => handleFieldChange("name", e.target.value)}
        />
        <Stack direction="row" gap={2}>
          {mode === SCRAPER_MODAL_MODES.CREATE && (
            <Autocomplete
              fullWidth
              onChange={(e, value) => handleFieldChange("scraper_type", value)}
              value={scraper?.scraper_type ?? ""}
              options={scraperTypes}
              groupBy={(option) => option.substring(0, option.indexOf("_"))}
              renderInput={(params) => (
                <TextField {...params} label="Scraper Type" />
              )}
            />
          )}
          {mode === SCRAPER_MODAL_MODES.UPDATE && (
            <>
              <Chip label={scraper?.scraper_type} />
              <Chip label={`Last scraped: ${lastScraped}`} />
            </>
          )}
          <Box flexGrow={1} />
          <Stack direction="row" alignItems="center" gap={1}>
            <Switch
              data-testid="scraperActive"
              checked={scraper?.is_active ?? false}
              onClick={(e) => handleFieldChange("is_active", e.target.checked)}
            />
            <Chip
              label={scraper?.is_active ? "Active" : "Inactive"}
              variant={scraper?.is_active ? "filled" : "outlined"}
              icon={
                <Box display="flex" fontSize="24px">
                  {scraper?.is_active ? (
                    <MaterialSymbol symbol="check" />
                  ) : (
                    <MaterialSymbol symbol="close" />
                  )}
                </Box>
              }
            />
          </Stack>
        </Stack>
        <TextField
          label="Query"
          value={scraper?.query ?? ""}
          multiline
          minRows={4}
          maxRows={8}
          onChange={(e) => handleFieldChange("query", e.target.value)}
        />
      </Stack>
      <Box sx={{ mx: -2 }}>
        <Accordion elevation={0} sx={{ bgcolor: "transparent" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="scraper-full-content"
            id="panel1-header"
          >
            <Typography variant="h6">Advanced</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ pl: "16px" }}>
              <CodeEditor
                value={JSON.stringify(scraper, null, 2) ?? ""}
                language="json"
                placeholder="Please enter JSON code."
                onChange={(e) => handleChange(e.target.value)}
                padding={15}
                style={{
                  backgroundColor: "#ffffff",
                  fontFamily:
                    "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      {scraper?.errors && (
        <Stack gap={2}>
          <Stack direction="row" justifyContent={"space-between"}>
            <Typography variant="h6">Errors</Typography>
            <Button
              variant="outlined"
              disabled={!scraper.errors?.length}
              onClick={() => handleFieldChange("errors", [])}
            >
              Clear Errors
            </Button>
          </Stack>
          {scraper?.errors?.map((error, index) => (
            <Stack key={`error-${index}`} gap={1} pl={2}>
              <Typography variant="subtitle1">{error.error}</Typography>
              <Typography variant="body2" sx={{ pl: 1 }}>
                {error.message}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack alignItems="end">
        <Button
          variant="contained"
          disabled={isEqual(defaultScraper, scraper)}
          color="primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </Stack>
      <ConfirmationDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleDelete}
        title="Delete Scraper"
        content="Are you sure you want to delete this scraper?"
      />
    </Stack>
  );
};

export default ScraperContent;
