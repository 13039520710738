import React from "react";
import { useParams } from "react-router-dom";

import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import ScraperContent from "components/ScraperContent/ScraperContent";
import { useScraper } from "hooks/useScraper";

const ScraperDetails = () => {
  const { scraperId } = useParams();
  const [remoteScraper, saveScraper] = useScraper(scraperId);
  return (
    <Page>
      <SubPage>
        {!!remoteScraper?.id && (
          <ScraperContent
            defaultScraper={remoteScraper}
            saveScraper={saveScraper}
          />
        )}
      </SubPage>
    </Page>
  );
};

export default ScraperDetails;
