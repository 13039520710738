import React, { useCallback, useState } from "react";
import { Link as ReactRouterLink, useSearchParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DataGridPro,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from "@tsc/component-library/lib/components";
import { addDoc, collection } from "firebase/firestore";
import { debounce } from "lodash";

import Page from "components/Layout/Page";
import SubPage from "components/Layout/SubPage";
import ScraperDialog from "components/ScraperDialog/ScraperDialog";
import dayjs from "configurations/dayjs";
import { db } from "configurations/firebase";
import { useScrapers } from "hooks/useScrapers";
import { useScraperTypes } from "hooks/useScraperTypes";

const Scrapers = () => {
  const scraperTypes = useScraperTypes();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const scraperType = searchParams.get("scraperType");
  const isActive = searchParams.get("isActive");
  const [scrapers, reload] = useScrapers(search, scraperType, isActive);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState({ search, scraperType });
  const handleChangedField = (field, value) => {
    setSearchParams({ [field]: value ?? "" });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleChangeField = useCallback(
    debounce(handleChangedField, 1500),
    []
  );
  const handleChangeFilters = (field, value) => {
    setFilters({ ...filters, [field]: value });
    handleChangedField(field, value);
  };
  const debouncedHandleChangeFilters = (field, value) => {
    setFilters({ ...filters, [field]: value });
    debouncedHandleChangeField(field, value);
  };
  const saveScraper = async (scraper) => {
    await addDoc(collection(db, "scrapers"), scraper);
    setOpen(false);
    reload();
  };

  return (
    <Page>
      <SubPage>
        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              label="Search"
              value={filters?.search ?? ""}
              placeholder="Scraper exact name, query, or ID"
              onChange={(e) =>
                debouncedHandleChangeFilters("search", e.target.value)
              }
            />
            <Autocomplete
              onChange={(e, value) => handleChangeFilters("scraperType", value)}
              value={filters?.scraperType ?? ""}
              options={scraperTypes}
              groupBy={(option) => option.substring(0, option.indexOf("_"))}
              renderInput={(params) => (
                <TextField {...params} label="Scraper Type" />
              )}
              sx={{ width: "250px" }}
            />
            <Autocomplete
              onChange={(e, value) => handleChangeFilters("isActive", value)}
              value={filters?.isActive ?? ""}
              options={["Active", "Inactive"]}
              renderInput={(params) => <TextField {...params} label="Active" />}
              sx={{ width: "250px" }}
            />
          </Stack>
          <Stack alignItems="end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              CREATE
            </Button>
          </Stack>
          <DataGridPro
            columns={columnDefinition}
            rows={scrapers}
            data-testid="scraperListTable"
            slots={{ loadingOverlay: Box }}
          />
          {open && (
            <ScraperDialog
              open={open}
              onClose={() => setOpen(false)}
              saveScraper={saveScraper}
            />
          )}
        </Stack>
      </SubPage>
    </Page>
  );
};

export default Scrapers;

const columnDefinition = [
  {
    headerName: "Name",
    field: "id",
    renderCell: ({ row }) => (
      <Link component={ReactRouterLink} to={`/scrapers/${row.id}`}>
        {row.name ?? row.query ?? row.id}
      </Link>
    ),
    sortable: false,
    minWidth: 200,
    flex: 1,
  },
  {
    headerName: "Type",
    field: "scraper_type",
    renderCell: ({ row }) => {
      if (!row.scraper_type) {
        return (
          <Warning message={"Scraper does not have a scraper_type field"} />
        );
      }
      return <Chip label={row.scraper_type ?? "unrecognized"} />;
    },
    sortable: false,
    minWidth: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    headerName: "Active",
    field: "is_active",
    renderCell: ({ row }) => {
      if (row.is_active === undefined) {
        return <Warning message={"Scraper does not have a is_active field"} />;
      }
      return <Switch disabled defaultChecked={row.is_active} />;
    },
    sortable: false,
    minWidth: 170,
    headerAlign: "center",
    align: "center",
  },
  {
    headerName: "Last scraped",
    field: "last_scraped",
    renderCell: ({ row }) => {
      let lastScraped = row?.last_scraped;
      try {
        if (!lastScraped) throw new Error();
        return dayjs(row?.last_scraped).fromNow();
      } catch (error) {
        return "Never";
      }
    },
    sortable: false,
    minWidth: 150,
  },
  {
    headerName: "",
    field: "warning",
    renderCell: ({ row }) => {
      const errors = [];
      const warnings = [];
      try {
        if (row.errors?.length > 0) {
          errors.push("This scraper has errors to review");
        }
        const last_scraped = Date.parse(row.last_scraped);
        if (isNaN(last_scraped)) {
          warnings.push("Never scraped");
        }
        if (Date.now() - last_scraped / 1000 < 86400) {
          warnings.push("Not scraped in the last 24 hours");
        }
        if (errors || warnings) {
          throw new Error();
        } else {
          return "";
        }
      } catch (error) {
        return (
          <Stack gap={1} direction="row">
            {!!warnings.length && (
              <Warning message={warnings.join("\n") ?? "Unknown warning"} />
            )}
            {!!errors.length && (
              <Box
                component="span"
                sx={{
                  color: "error.main",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Tooltip title={errors.join("\n") ?? "Unknown error"}>
                  <ErrorOutlineIcon />
                </Tooltip>
              </Box>
            )}
          </Stack>
        );
      }
    },
    sortable: false,
    headerAlign: "center",
    align: "center",
  },
];

const Warning = ({ message }) => {
  return (
    <Box
      component="span"
      sx={{
        color: "warning.main",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip title={message}>
        <WarningAmberOutlinedIcon />
      </Tooltip>
    </Box>
  );
};
