import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { doc, getDoc, setDoc } from "firebase/firestore";

import { db } from "configurations/firebase";
import { serverErrorNotification } from "features/notifications/notificationSlice";

export const useScraper = (scraperId) => {
  const dispatch = useDispatch();
  const [scraper, setScraper] = useState(null);

  const getScraper = useCallback(async () => {
    if (scraperId) {
      getDoc(doc(db, `scrapers/${scraperId}`))
        .then((doc) => setScraper({ id: doc.id, ...doc.data() }))
        .catch((error) => {
          dispatch(serverErrorNotification(error));
        });
    } else {
      setScraper(null);
    }
  }, [scraperId, dispatch]);

  useEffect(() => {
    getScraper();
  }, [getScraper]);

  const update = async (scraper) => {
    try {
      await setDoc(doc(db, `scrapers/${scraperId}`), scraper);
      await getScraper();
    } catch (error) {
      dispatch(serverErrorNotification(error));
    }
  };

  return [scraper, update];
};
