import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { collection, getDocs, or, query, where } from "firebase/firestore";

import { db } from "configurations/firebase";
import { serverErrorNotification } from "features/notifications/notificationSlice";

export const useScrapers = (search, scraperType, isActive) => {
  const dispatch = useDispatch();
  const [scrapers, setScrapers] = useState([]);

  const load = useCallback(() => {
    const conditions = [];
    if (search)
      conditions.push(
        or(
          where("name", "==", search),
          where("query", "==", search),
          where("id", "==", search)
        )
      );
    if (scraperType) conditions.push(where("scraper_type", "==", scraperType));
    if (isActive === "Active" || isActive === "Inactive") {
      conditions.push(where("is_active", "==", isActive === "Active"));
    }
    getDocs(query(collection(db, `scrapers`), ...conditions))
      .then((docs) => {
        const scrapers = [];
        docs.forEach((doc) => scrapers.push({ id: doc.id, ...doc.data() }));
        setScrapers(scrapers);
      })
      .catch((error) => {
        dispatch(serverErrorNotification(error));
      });
  }, [dispatch, isActive, scraperType, search]);

  useEffect(() => {
    load();
  }, [dispatch, load, scraperType]);

  return [scrapers, load];
};
