import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { doc, getDoc } from "firebase/firestore";

import { db } from "configurations/firebase";
import { serverErrorNotification } from "features/notifications/notificationSlice";

export const useScraperTypes = () => {
  const dispatch = useDispatch();
  const [scraperTypes, setScraperTypes] = useState([]);

  useEffect(() => {
    getDoc(doc(db, "master_data/scraper_types"))
      .then((doc) => {
        setScraperTypes(doc.data()?.types ?? []);
      })
      .catch((error) => {
        dispatch(serverErrorNotification(error));
      });
  }, [dispatch]);

  return scraperTypes;
};
