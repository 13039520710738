import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Editor,
  IconButton,
  LabeledComponent,
  TextField,
} from "@tsc/component-library/lib/components";
import { EDITOR_FORMAT } from "@tsc/component-library/lib/components/Editor/Editor";
import {
  useGeographiesDropdownUtils,
  useInputs,
} from "@tsc/component-library/lib/hooks";
import { STAKEHOLDER_SOURCE } from "enums/stakeholderSource";
import { STAKEHOLDER_TYPE } from "enums/stakeholderType";

import { useGenie } from "hooks/useGenie";
import { getStakeholderAttribute } from "utilities/stakeholder";

const DEFAULT_VALUE = {
  name: "",
  headline: "",
  summary: "",
};
const EDITOR_TOOLBAR_OPTIONS = [
  [{ header: [4, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
];

const StakeholderAttributeEditorDialog = ({
  open,
  onClose,
  onSubmit,
  stakeholder,
  value,
  loading: isUpdatingSA,
}) => {
  const [inputs, setInput, setInputs] = useInputs(DEFAULT_VALUE);
  const { optionsMap } = useGeographiesDropdownUtils();

  const {
    isFetching: isGeneratingSummary,
    lastMessage,
    askSuperprompt,
  } = useGenie();

  const loading = isUpdatingSA || isGeneratingSummary;

  const handleClose = () => {
    if (!onClose || loading) {
      return;
    }
    onClose();
  };

  const handleSubmit = () => {
    if (!onSubmit || loading) {
      return;
    }
    onSubmit(inputs);
  };

  const handleGenerateSummary = async () => {
    const superpromptId =
      stakeholder?.type === STAKEHOLDER_TYPE.PERSON
        ? "STAKEHOLDER_PROFILE_GENERATE_PERSON_BIO"
        : "STAKEHOLDER_PROFILE_GENERATE_ORGANIZATION_BIO";
    const stakeholderAttributes =
      stakeholder?.stakeholderAttributes.filter(
        (sa) => sa.source !== STAKEHOLDER_SOURCE.GPT
      ) ?? [];
    const name = getStakeholderAttribute({ stakeholderAttributes }, "name");
    const headline = getStakeholderAttribute(
      { stakeholderAttributes },
      "headline"
    );
    const country = stakeholder?.countryIds
      ?.map((countryId) => optionsMap[countryId]?.name ?? countryId)
      .join(", ");
    const context = {
      stakeholder: {
        ...stakeholder,
        summary: stakeholderAttributes
          .map((sa, index) => `Summary ${index}\n---\n${sa.summary}`)
          .join("\n\n"),
        country: country,
      },
      google_search: {
        query: `${name} - ${headline}` + (country ? ` in ${country}` : ""),
      },
    };
    askSuperprompt(superpromptId, null, context, { noCache: true });
  };

  useEffect(() => {
    if (open) {
      setInputs({ ...DEFAULT_VALUE, ...value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open && lastMessage) {
      setInputs({ ...value, summary: lastMessage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Edit Stakeholder Attribute
        <IconButton
          size="small"
          sx={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ display: "flex", rowGap: 2, flexDirection: "column" }}
      >
        {value?.source !== STAKEHOLDER_SOURCE.GPT && (
          <LabeledComponent title="Name" vertical removeMargin>
            <TextField
              autoFocus
              fullWidth
              inputProps={{ maxLength: 4096 }}
              value={inputs.name}
              onChange={(event) => setInput("name", event.target.value)}
              disabled={loading || value?.source === STAKEHOLDER_SOURCE.GPT}
            />
          </LabeledComponent>
        )}

        {value?.source !== STAKEHOLDER_SOURCE.GPT && (
          <LabeledComponent title="Headline" vertical removeMargin>
            <TextField
              fullWidth
              inputProps={{ maxLength: 256 }}
              value={inputs.headline}
              onChange={(event) => setInput("headline", event.target.value)}
              disabled={loading}
            />
          </LabeledComponent>
        )}

        <LabeledComponent title="Summary" vertical removeMargin>
          <Editor
            isUncontrolled
            readOnly={loading}
            minHeight={200}
            format={EDITOR_FORMAT.MARKDOWN}
            value={inputs.summary}
            defaultValue={inputs.summary}
            onBlur={(value) => setInput("summary", value)}
            toolbarOptions={EDITOR_TOOLBAR_OPTIONS}
            placeholder={
              value?.source === STAKEHOLDER_SOURCE.GPT
                ? "Click on the button below to generate summary."
                : null
            }
          />
        </LabeledComponent>

        {value?.source === STAKEHOLDER_SOURCE.GPT && (
          <Button
            variant="outlined"
            disabled={loading}
            onClick={handleGenerateSummary}
            startIcon={
              isGeneratingSummary ? (
                <CircularProgress size={14} color="inherit" />
              ) : null
            }
          >
            {!loading && inputs.summary ? "Re-" : ""}Generate Summary
          </Button>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
          startIcon={
            isUpdatingSA ? <CircularProgress size={14} color="inherit" /> : null
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StakeholderAttributeEditorDialog;
